@charset "utf-8";

// Import a Google Font
// @import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
// $purple: #8a4d76;
// $pink: #fa7c91;
// $brown: #757763;
// $beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$bg-color: hsl(0, 0%, 98%);

// Update Bulma's global variables
// $family-sans-serif: 'Nunito', sans-serif;
// $grey-dark: $brown;
// $grey-light: $beige-light;
// $primary: $purple;
// $link: $pink;
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $bg-color;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;
$box-shadow: 0 0.5em 1em -0.7em rgba(10, 10, 10, 0.1),
  0 0 0 2px rgba(10, 10, 10, 0.02);

// Import only what you need from Bulma
// @import '../node_modules/bulma/sass/utilities/_all.sass';
// @import '../node_modules/bulma/sass/base/_all.sass';
// @import '../node_modules/bulma/sass/elements/button.sass';
// @import '../node_modules/bulma/sass/elements/container.sass';
// @import '../node_modules/bulma/sass/elements/form.sass';
// @import '../node_modules/bulma/sass/elements/title.sass';
// @import '../node_modules/bulma/sass/components/navbar.sass';
// @import '../node_modules/bulma/sass/layout/hero.sass';
// @import '../node_modules/bulma/sass/layout/section.sass';
@import '../node_modules/bulma/bulma.sass';
@import '../node_modules/bulma-helpers/css/bulma-helpers.min.css';

.time-sep {
  &:before {
    content: ':';
    animation: timer 2s infinite;
  }
}

@keyframes timer {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.react-datepicker-popper {
  z-index: 10;
}
